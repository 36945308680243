import React from "react"
import { graphql } from "gatsby"
// import BackgroundImage from "gatsby-background-image"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import CardDeck from "react-bootstrap/CardDeck"

import Layout from "../components/layout/basic"
import SEO from "../components/global/SEO"
import Features from "../components/reasons"
// import Blog from "../components/blog/LatestPosts"
import CoverMap from "../components/mapCover"
import OfferCarousel from "../components/OfferCarousel"

import ServiceCard from "../components/ServiceCard"
import TariffCard from "../components/TariffCard"

const InternetFlat = ({ data: { OgImage, tariffs, services, offers } }) => {
  // const gradientImage = [
  //   flat.fluid,
  //   `radial-gradient(at 50% 100%, rgba(181, 45, 168, 0.6), rgba(15, 1, 94, 0.75))`,
  // ].reverse()

  return (
    <Layout>
      <SEO
        title="Інтернет в Квартиру"
        description="Підключити Швидкий Інтернет і Телебачення в Квартирах від Інтернет-Провайдера Новий Телеком (Newtelecom) в селах Київської області, Бучанський район (Київо-Святошинський), інтернет по Житомирській трасі"
        image={`https:${OgImage.fixed.src}`}
        url="/internet-v-kvartyru/"
      />
      <div className="hero-background-light py-5">
        <Container className="my-5">
          <h1 className="gradient-text">Інтернет в Квартиру</h1>
          <div className="hero-slogan mt-4">
            Оберіть тариф на Швидкісний безлімітний Інтернет в квартиру за вашою
            адресою у селах Бучанського району Київської області
            (Києво-Святошинський й Макарівський райони).
          </div>
        </Container>
      </div>
      <section className="internet-tarifs my-5 pb-5">
        <Container>
          <Row className="justify-content-center">
            {tariffs.nodes.map(tariff => (
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={4}
                className="mb-4 mb-lg-0"
                key={tariff.id}
              >
                <TariffCard
                  tariffImage={tariff.image.fluid} //fluid
                  tariffName={tariff.tariffName}
                  tariffDescription={tariff.description.description}
                  tariffPrice={tariff.tariffPrice}
                  tariffWorldSpeed={tariff.tariffWorldSpeed}
                  tariffUkrSpeed={tariff.tariffUkrSpeed}
                  tariffServicesIncluded={tariff.tariffServicesIncluded}
                  tariffPurpose={tariff.tariffPurpose.tariffPurposeName}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <Container>
        <Features />
      </Container>
      <section>
        <Container>
          <h2 className="mb-4 gradient-text text-uppercase">Послуги</h2>
          <Row>
            <CardDeck>
              {services.nodes.map(service => (
                <ServiceCard
                  key={service.id}
                  serviceTitle={service.serviceName}
                  servicePrice={service.servicePrice}
                  serviceDescription={service.description.description}
                />
              ))}
            </CardDeck>
          </Row>
        </Container>
      </section>
      <CoverMap />
      <OfferCarousel offers={offers.nodes} offerUrl="/promo/" />
      {/* <Devices /> */}
      {/* <Blog /> */}
    </Layout>
  )
}

export default InternetFlat

export const query = graphql`
  {
    flat: contentfulAsset(title: { eq: "flat" }) {
      fluid(maxWidth: 1920) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    OgImage: contentfulAsset(title: { eq: "flat" }) {
      fixed(width: 620, height: 460) {
        src
      }
    }
    tariffs: allContentfulTariff(
      filter: {
        tariffPurpose: { tariffPurposeName: { eq: "flat" } }
        isActive: { eq: true }
        node_locale: { eq: "uk" }
      }
      sort: { fields: tariffPrice, order: ASC }
    ) {
      nodes {
        id
        isActive
        tariffName
        tariffPrice
        tariffUkrSpeed
        tariffWorldSpeed
        image {
          fluid(maxWidth: 350) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        tariffServicesIncluded {
          serviceName
        }
        description {
          description
        }
        tariffPurpose {
          tariffPurposeName
        }
      }
    }
    services: allContentfulService(
      sort: { fields: orderId, order: ASC }
      filter: {
        isActive: { eq: true }
        categories: {
          elemMatch: {
            tariffPurposeName: { eq: "flat" }
            node_locale: { eq: "uk" }
          }
        }
      }
    ) {
      nodes {
        id
        serviceName
        servicePrice
        description {
          description
        }
      }
    }
    offers: allContentfulPromoOffer(
      filter: {
        node_locale: { eq: "uk" }
        category: { elemMatch: { tariffPurposeName: { eq: "flat" } } }
      }
    ) {
      nodes {
        id
        title
        slogan {
          childMarkdownRemark {
            html
          }
        }
        offerImage {
          fluid(maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`
